<template>
  <div class="single blog page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">Should I take online Spanish lessons or face-to-face?</h2>
    </section>
    <div class="container 75%">
      <section class="myspanishnow-story row 200%">
        <div>
          <p>Hola {{ getCurrentSeasonHeart() }}</p>
          <p>Before starting, I would like to highlight that you don’t have to be a computer engineer (I am not either) to be able to learn online. Nowadays, our current technology makes online lessons seamlessly. 😌</p>
          <p>After teaching both online and face-to-face I can say for certain that both of them can work really well. It all depends on YOUR preferences and circumstances ⬇️</p>
          <h3>What to socialise?</h3>
          <p>Well, if one of your preferences is to socialise with your colleagues or your teacher, then face-to-face lessons might be a better option for you. Of course, in an online lesson you also have to (digitally) socialise with the other(s) to learn but is not the same - also, make sure you pick group lessons to socialise.</p>
          <h3>Flexibility?</h3>
          <p>If time or location is a problem, then online lessons might be a better option. As long as you have a laptop and a good internet connection, you can always attend your lesson regardless of where you are. And if you have a tight schedule, one-to-one online lessons might be even more advantageous because you should be able to change the time of the lessons accordingly, which don’t normally happen in a group lesson.</p>
          <h3>Tangible activities or interactive activities?</h3>
          <p>During all these years of teaching, I have had students who love interactive activities - activities that recur to technology -, and those, who, on the contrary, prefer tangible activities - activities that you can get the hands on - because for them using different technology tools ''drives them crazy''. ¡Fácil! In my case, I adapt the lesson to your needs and preferences! For instance, in a face-to-face lesson, you could use your phone or computer for an interactive activity. And during an online lesson, I would send you, in advance, tangible material to print before the lesson.</p>
          <p>Now I will mention the most <b>famous answers</b> of why my students like learning online:</p>
          <blockquote class="blockquote text-center">
            <p>my school and teacher can be from many miles away from me but I can easily attend my lessons with a click of a button - besides there is never traffic</p>
          </blockquote>
          <blockquote class="blockquote text-center">
            <p>I am very lucky to choose the school with which I want to learn, whatever the reason, from its methodology, its values, the teacher, or the services and options that it offers me, no matter how far away I am</p>
          </blockquote>
          <p>See you soon until then, SONRÍE 😊 {{ getCurrentSeasonEmoji() }}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'FindThePerfectTutor',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>
  img {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 2em;
  }

  .blog.single > .container {
    margin-top: 50px;
  }

  blockquote {
    margin-bottom: 2em;
  }

  blockquote p {
    margin: 0;
  }

  ul {
    list-style: none;
  }
</style>
